@use "/src/scss/abstracts" as *;

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

.page-title {
    font-weight: 700;
    font-size: $type-size-400;
    color: $gray-900;

    &__watershed {
        display: flex;
        align-items: center;
        gap: $spacing-200;

        .icon-wrapper {
            color: $blue-500;
        }

        ::ng-deep {
            svg {
                width: 1.25rem;
                height: 1.25rem;
                display: block;
            }
        }

        icon {
            display: block;
        }

        .wria-id {
            display: block;
            padding-top: 3px;
            color: $gray-500;
            font-size: $type-size-200;
            font-weight: 400;
        }
    }
}

.section-title {
    font-weight: 700;
    font-size: $type-size-400;
    padding-bottom: 0.5rem;

    @include desktop-small {
        font-size: $type-size-500;
    }

    &.primary {
        color: $primary;
    }

    &.underline {
        border-bottom: 2px solid $tan;
        margin-bottom: 1rem;
    }
}

.module-title {
    font-size: $type-size-200;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: bold;
    padding-bottom: 0.5rem;
    color: $primary;

    &.underline {
        border-bottom: 2px solid $primary;
        margin-bottom: 1rem;
    }
}

.parcels__title {
    svg path {
        fill: $primary;
    }
}
