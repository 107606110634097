@use "/src/scss/abstracts" as *;

.scrubber_group {
    cursor: ew-resize;
    filter: grayscale(1);
}

#vg-tooltip-element {
    font-size: 14px;
}

.chartSection {
    border: 1px solid $gray-200;
    box-shadow: $shadow-100;
    border-radius: $border-radius-200;
    background-color: #fff;
    padding-top: $spacing-300;
    padding-bottom: $spacing-300;
    padding-left: $spacing-600;
    padding-right: $spacing-600;
}

.chartLegend {
    display: flex;
    gap: $spacing-400;
}

.legend {
    display: flex;
    gap: $spacing-600;

    & > div {
        display: flex;
        align-items: center;
        gap: $spacing-100;
    }

    i {
        border-radius: $border-radius-200;
        width: $spacing-400;
        height: $spacing-400;
    }

    .legendEntryExtraInfo {
        color: $gray-500;
        font-weight: bold;
    }
}
