@use "/src/scss/abstracts" as *;

body {
    font-family: $font-family-sans-serif;
    font-optical-sizing: auto;
    line-height: 1.5;
}

a {
    color: $primary;
}

button {
    font-family: $font-family-sans-serif;
    font-optical-sizing: auto;
}

.site-footer a {
    color: $white;
    text-decoration: underline;
}
