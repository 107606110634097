@use "/src/scss/abstracts" as *;

.leaflet-container {
    font-family: $font-family-sans-serif !important;
}

.leaflet-draw-section a {
    &:hover {
        color: white;
        text-decoration: none;
    }
}

.leaflet-control-layers-close {
    color: #0078a8 !important;
    border: none;
    background: none;
    cursor: pointer;
    float: right;
    display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-close {
    display: block;
}

// for the get action boundaries
.selecting-location .leaflet-interactive.boundary {
    cursor: crosshair !important;
}

.wria-label,
.county-label {
    border: 0;
    background-color: transparent;
    box-shadow: none;
    font-weight: bold;
}

.leaflet-popup-content-wrapper {
    border-radius: $border-radius-200;
    border: 1px solid $blue-200;
}

.leaflet-popup-content {
    width: 320px !important;
    line-height: unset !important;
}

.site {
    .leaflet-container a.leaflet-popup-close-button {
        color: $white;
        border: 2px solid;
        border-radius: 50%;
        top: 8px;
        right: 8px;

        span {
            position: absolute;
            top: -2px;
            right: 4px;
        }

        &:hover {
            color: $green-600;
            background: $white;
            border-color: $white;
        }
    }
}

.leaflet-left .leaflet-custom-controls {
    display: flex;

    a {
        border-bottom: 0;

        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.leaflet-left a.leaflet-control-zoom-custom {
    cursor: pointer;
    margin-left: 1px;
    margin-top: 0;
}

// leaflet.groupedlayercontrol.js
.leaflet-control-layers-group-name {
    font-weight: 700;
    margin-bottom: 0.2em;
    margin-left: 3px;
}

.leaflet-control-layers-group {
    margin-bottom: 0.5em;
}

.leaflet-control-layers-scrollbar {
    overflow-y: scroll;
    padding-right: 10px;
}

.leaflet-control-layers-group-label {
    margin-bottom: 2px;
}

.leaflet-control-layers-group-selector,
.leaflet-control-layers-selector {
    vertical-align: top;
}

.leaflet-control-layers-group.group-collapsable.collapsed .leaflet-control-layers-group-collapse,
.leaflet-control-layers-group.group-collapsable:not(.collapsed) .leaflet-control-layers-group-expand,
.leaflet-control-layers-group.group-collapsable.collapsed label:not(.leaflet-control-layers-group-label) {
    display: none;
}

.leaflet-control-layers-group-expand-default:before {
    content: "+";
    width: 12px;
    display: inline-block;
    text-align: center;
}

.leaflet-control-layers-group-collapse-default:before {
    content: "-";
    width: 12px;
    display: inline-block;
    text-align: center;
}

.leaflet-control-layers-list a.leaflet-control-layers-close-button {
    cursor: pointer;
}

.leaflet-control-layers-close-button-wrapper {
    padding-top: $spacing-200;
    padding-right: $spacing-100;
    text-align: right;
}
