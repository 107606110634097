@use "/src/scss/abstracts" as *;

.dropdown {
    &-menu {
        display: none;

        @include desktop-medium {
            position: absolute;
            z-index: 1111;
            top: 2rem;
            left: 0;
            background: white;
            width: 16rem;
            border-radius: 0.25rem;
            box-shadow: $shadow-100;
        }

        &-right {
            @include desktop-medium {
                left: unset;
                right: 0;
            }
        }

        &.active {
            padding-top: 0.5rem;
            display: block;

            @include desktop-medium {
                padding-top: unset;
            }
        }
    }

    &-item {
        display: block;
        font-size: $type-size-300;
        padding: 1rem 0;
        color: $white;

        @include desktop-medium {
            padding: 1rem;
            transition: all 300ms ease;
            font-size: $type-size-200;
            color: $secondary;
        }

        &:hover {
            color: $white;

            @include desktop-medium {
                background: $secondary;
                color: $white;
            }
        }

        &:first-of-type {
            @include desktop-medium {
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
            }
        }

        &:last-of-type {
            padding-bottom: 0;

            @include desktop-medium {
                padding-bottom: 1rem;
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
        }

        & + .dropdown-item {
            border-top: 1px solid rgba(white, 0.05);

            @include desktop-medium {
                border-top: 1px solid rgba(black, 0.05);
            }
        }
    }
}
