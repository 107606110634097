@use "/src/scss/abstracts" as *;

.guide {
    .guide-title {
        @include desktop-medium {
            font-size: $type-size-600;
        }
    }

    .about {
        &__info {
            @include desktop-medium {
                grid-column: 1 / span 7;
            }
        }

        &__photo {
            margin-bottom: 2rem;

            img {
                width: 100%;
            }
        }

        .faqs {
            @include desktop-medium {
                grid-column: 9 / span 4;
                border-left: 1px solid rgba($black, 0.1);
                padding-left: 2rem;
            }
        }
    }

    .glossary {
        padding-top: 3rem;

        @include desktop-medium {
            padding-top: 6rem;
        }

        &__grid {
            display: grid;
            gap: 2rem;

            @include tablet {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @include desktop-small {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }

        &__word {
            color: $white;
            background: $primary;
            padding: 1rem 1rem 0.75rem 1rem;
            font-size: $type-size-300;
            line-height: 1.25em;
        }

        &__definition {
            padding: 1.5rem 1rem;
            border: 1px solid rgba($black, 0.1);
            border-top: none;
        }
    }

    .faqs {
        &__grid {
            display: grid;
            gap: 3rem;
        }
    }

    .faq {
        &__question {
            font-weight: bold;
            color: $white;
            background: $secondary;
            padding: 0.75rem 0.75rem 0.5rem 0.75rem;
            font-size: $type-size-300;
            line-height: 1.25em;
            margin-bottom: 1rem;
        }

        &__answer {
            padding: 0 0.75rem;
        }
    }

    .annotations-header {
        padding-top: 3rem;
        text-align: center;

        @include desktop-medium {
            padding-top: 6rem;
        }
    }

    .annotated-figure {
        margin-top: 3rem;
        border-top: 1px solid rgba($black, 0.1);
        padding-top: 3rem;
        align-items: start;

        @include desktop-medium {
            margin-top: 6rem;
            padding-top: 6rem;

            &.water-budget {
                margin-top: 2rem;
            }
        }
    }

    .screenshot {
        @include desktop-medium {
            grid-column: span 8;
        }

        @include desktop-x-large {
            grid-column: span 9;
        }

        img {
            width: 100%;
        }
    }

    .captions {
        display: grid;
        gap: 2rem;

        @include tablet {
            display: block;
            columns: 2;
            column-gap: 2rem;
        }

        @include desktop-small {
            columns: 3;
        }

        @include desktop-medium {
            display: grid;
            columns: unset;
            grid-column: span 4;
        }

        @include desktop-x-large {
            grid-column: span 3;
            padding-top: 4rem;
        }
    }

    .caption {
        display: grid;
        gap: 1rem;
        grid-template-columns: 2rem minmax(0, 1fr);

        @include tablet {
            margin-bottom: 2rem;
        }

        @include desktop-medium {
            margin-bottom: 0;
        }

        &__item {
            background: $secondary;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            line-height: 2rem;
            font-weight: bold;
            font-size: $type-size-200;

            span {
                display: block;
                transform: translateY(2px);
            }
        }
    }
}
