$blue-lighter: #e7eff1;
$blue-light: #b4ccd4;
$blue-default: #0d5b73;
$blue-dark: #0c5268;
$blue-darker: #083745;

$blue-050: #f3f6fc;
$blue-100: #e6eef8;
$blue-200: #c7daf0;
$blue-300: #96bce3;
$blue-400: #5e99d2;
$blue-500: #3a7cbd;
$blue-600: #2961a0;
$blue-700: #234f81;
$blue-800: #1f4168;
$blue-900: #203a5a;
$blue-950: #15253c;

$green-050: #f1f8f4;
$green-100: #ddeee2;
$green-200: #bdddc8;
$green-300: #92c3a6;
$green-400: #63a47f;
$green-500: #428762;
$green-600: #367957;
$green-700: #26563f;
$green-800: #204533;
$green-900: #1b392b;
$green-950: #0e2018;

$yellow-050: #fffbeb;
$yellow-100: #fdf2c8;
$yellow-200: #fae58d;
$yellow-300: #f8d354;
$yellow-400: #f6bf29;
$yellow-500: #ef9f11;
$yellow-600: #d4790b;
$yellow-700: #b0550d;
$yellow-800: #8f4211;
$yellow-900: #763711;
$yellow-950: #431b05;

$red-lighter: #fdf0f0;
$red-light: #f9d1d1;
$red-default: #ed6969;
$red-dark: #be4c4c;
$red-darker: #822d2d;

$gray-lighter: #fafafa;
$gray-light: #d5d5d5;
$gray-default: #8a8a8a;
$gray-dark: #4e4e4e;
$gray-darker: #2a2a2a;

$gray-050: #fafafa;
$gray-100: #efefef;
$gray-200: #dcdcdc;
$gray-300: #bdbdbd;
$gray-400: #989898;
$gray-500: #7c7c7c;
$gray-600: #656565;
$gray-700: #525252;
$gray-800: #464646;
$gray-900: #3d3d3d;
$gray-950: #292929;
$gray-1000: #000;

$white: #ffffff;
$black: #000000;

$primary: $blue-800;
$secondary: $green-600;
$tan: #f4f3ef;
