@use "/src/scss/abstracts" as *;

.card {
    padding: 1.5rem 1.5rem 1.125rem 1.5rem;
    box-shadow: $shadow-300;
    border-radius: 0.25rem;

    &-55 {
        max-width: 55rem;
    }

    &-header {
        background: unset;
        border-bottom: 1px solid rgba(black, 0.1);
        padding: 0 0 1.125rem 0;
        // border-top: 0.5rem solid $secondary;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    &-actions {
        & > * + * {
            margin-left: 1rem;
        }
    }

    &-title {
        font-size: $type-size-500;
        font-weight: 700;
        margin: 0;
        padding: 0;

        i {
            font-size: $type-size-200;
            transform: translateY(-6px);
        }
    }

    &-body {
        padding: 1.5rem 0;
    }

    &-footer {
        border-top: 1px solid rgba(black, 0.05);
        border-bottom: 1px solid rgba(black, 0.05);
        padding: 1.5rem 0;
        gap: 0.5rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
}
